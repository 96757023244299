import { create } from "ipfs-http-client";
import { BufferList } from "bl";
import fs from 'fs'
import axios from 'axios';
const auth =
  'Basic ' + btoa(process.env.REACT_APP_INFURA_IPFS_PROJECT_ID + ':' + process.env.REACT_APP_INFURA_IPFS_API_SECRET);
// export const ipfs = create(
//   {
//     host: 'ipfs.infura.io',
//     port: 5001,
//     protocol: 'https',
//     headers: {
//       authorization: auth,
//     },
//   }
// );

const PINATA_GATEWAY = 'https://magenta-neighbouring-crab-308.mypinata.cloud/ipfs/'

export const ipfs = create(
  {
    url: PINATA_GATEWAY,
    // port: 443,
    // headers: {
    //   "x-pinata-gateway-token": `gUatm6Cvr7H6IOTi-A993LkM8alGmnrF2dDcgkwVzmoBYE6Vl11wKWpXJc3Do-Uz`,
    // },
  }
);

// export const ipfs = create({ host: "localhost", port: "5001", protocol: "http" });


export async function addToIPFS(file) {
  const fileAdded = await ipfs.add(file);

  return fileAdded;
}

export function urlFromCID(cid) {
  return `https://ipfs.infura.io/ipfs/${cid}`;
}

export async function getFromIPFS(hashToGet) {
  for await (const file of ipfs.cat(hashToGet)) {
    const content = new BufferList(file).toString();

    return content;
  }
}

export async function getFromPinataGateway(hashTGet) {
  // console.log("GET From Gateway", hashTGet)
  let url = `${PINATA_GATEWAY}${hashTGet}`
  return axios.get(url)
    .then(response => {
      const jsonData = response.data;
      // Process the JSON data
      // console.log("JSON DATA", jsonData);
      return jsonData
    })
    .catch(error => {
      console.log(error);
    });


}


export function convertToGatewayUrl(url) {
  let converted = url.replace("https://ipfs.io/ipfs/",PINATA_GATEWAY)
  return converted
}


