import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {getFromIPFS} from "../helpers/ipfs";
import {Button, Image, Typography, Layout, Space, Card, Tooltip} from 'antd'
import {useContractReader} from "eth-hooks";
import {RightOutlined,FileImageOutlined, LeftOutlined} from "@ant-design/icons"
import {fetchExhibitData} from "../helpers/fetchExhibitData";
import DrWipBanner from "../components/DrWipBanner";
import {Link} from "react-router-dom";

const { Title, Paragraph, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;

const ExhibitPage = ({readContracts, writeContracts, address, tx}) => {
  let [exhibit, setExhibit] = React.useState(null);
  let [originalVisible, setOriginalVisible] = React.useState(false);
  const {exhibitId} = useParams()
  const navigate = useNavigate();

  const tokenSupply = useContractReader(readContracts, "IIExhibition", "tokenSupply", [exhibitId]);


  React.useEffect(() => {
    setExhibit(null)
    const fetchExhibit = async () => {
      let exhibitData = null;


      try {
        let tokenSupply = await readContracts.IIExhibition.tokenSupply(exhibitId);
        let isMember = false;
        if (address) {
          isMember = await readContracts.IIExhibition.isMember(exhibitId, address);
        }
        console.log("Is Member", isMember)
        let uri = await readContracts.IIExhibition.uri(exhibitId); //All tokens have the same base uri
        exhibitData = await fetchExhibitData(uri, exhibitId)

        // TODO: better error handling
        if (exhibitData) {
          exhibitData.supply =tokenSupply;
          exhibitData.isMember =isMember;
        }


      } catch (e) {
        console.log(e);
      }

      setExhibit(exhibitData);
    };
    readContracts && readContracts.IIExhibition && fetchExhibit();
  }, [readContracts, exhibitId]);


  async function mintMembership() {
    let cost = 5000000000000000;
    const result = tx(writeContracts.IIExhibition.mint(exhibitId, {value: cost}), update => {
      console.log("📡 Transaction Update:", update);
      if (update && (update.status === "confirmed" || update.status === 1)) {
        console.log(" 🍾 Transaction " + update.hash + " finished!");
        console.log(
          " ⛽️ " +
          update.gasUsed +
          "/" +
          (update.gasLimit || update.gas) +
          " @ " +
          parseFloat(update.gasPrice) / 1000000000 +
          " gwei",
        );
      }
    });
    console.log("awaiting metamask/web3 confirm result...", result);
    console.log(await result);
  }


  return (
    <div>
      <div>
        <Title style={{fontSize: "2em", fontFamily: "Montserrat, sans-serif"}}>
          Identity and Interaction <span>in</span> Complex Human Systems
        </Title>

      </div>
      {exhibit &&
        <>

      <div>
        <Space align="center">
        <Card

          style={{margin: "10px", maxWidth: "800px"}}
          bordered={true}
          cover={
            <div className="picture-frame">

              <Image style={{width:"100%"}} src={exhibit.image} />

            </div>

            // <Image style={{width:"100%"}} src={exhibit.image.replace("https://ipfs.io/ipfs/", "https://gateway.pinata.cloud/ipfs/")} />
          }
          actions={[
            <LeftOutlined style={{fontSize: '36px'}} onClick={() => navigate(`/exhibit/${parseInt(exhibitId)-1 === 0 ? 12 : parseInt(exhibitId)-1 }`)}/>,
            <Tooltip placement="bottom" title="View original">
            <FileImageOutlined style={{fontSize: '36px'}} onClick={() => setOriginalVisible(true)}/>
          </Tooltip>,
            <RightOutlined style={{fontSize: '36px'}} onClick={() => navigate(`/exhibit/${parseInt(exhibitId)%12+1}`)} />]}
        >
          <Card.Meta
            title={<Title style={{fontFamily: "Montserrat, sans-serif"}}>{exhibitId}. {exhibit.name}</Title>}
            description={<div>
              {exhibit.description.split('\n\n').map(para => {
                return <Paragraph style={{fontSize: "18px", fontFamily: "Merriweather, serif"}} >{para}</Paragraph>
              })
              }
            </div>}
          />
          {/*<div>Owned {exhibit.isMember.toString()}</div>*/}
        </Card>
        </Space>



        {/*<div>Members : {tokenSupply.toString()}</div>*/}


        {originalVisible && <Image preview={{visible: originalVisible, onVisibleChange: (visible, prevVisible) => setOriginalVisible(visible)}}  style={{width:"100%"}} src={exhibit.original} />}

        {/*<div>{exhibit.original}</div>*/}
      </div>
          {/*<Footer>*/}
            {/*{!exhibit.isMember && <Button onClick={mintMembership} >Mint</Button>}*/}
          {/*</Footer>*/}
        </>}
    </div>
  )
}

export default ExhibitPage
