import React from 'react';
import {Row, Typography} from 'antd';
import InfBannerText from "../components/InfBannerText";
import {InfoCircleOutlined, EditOutlined, BookOutlined, MessageOutlined} from "@ant-design/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const {Title, Paragraph, Text} = Typography;

const About = () => {

  return (
    <div style={{display: "flex", flexDirection: "column", "textAlign": "left", "padding": "0px auto", alignItems: "center"}}>
      <div style={{maxWidth: "900px", padding: "20px"}}>
        <Title style={{fontSize: "2em", fontFamily: "Montserrat, sans-serif"}}>
          How this exhibition came to be
        </Title>
        <div style={{paddingTop: "10px"}}>
        <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
          <InfoCircleOutlined style={{fontSize: "40px", padding: "20px"}}/>
          <div>
            Conceptual renderings presented in this exhibition were produced during moments of contemplation and clarity throughout the course of my PhD.
          </div>
        </div>
        <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
          <EditOutlined style={{fontSize: "40px", padding: "20px"}}/>
          <div>
            Almost all exhibits originated as sketches in one of my notebooks, created whilst wandering around the beautiful city of Edinburgh and its inspiring surroundings.
            Each exhibit includes a picture of the source sketch, which in many cases are more detailed than their digital rendering.
          </div>
        </div>
        <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
          <BookOutlined style={{fontSize: "40px", padding: "20px"}}/>
          <div>
            The diagrams were initially included in the appendix on my thesis titled -
            Identity and Identification in an Information Society: Augmenting Formal Systems of Identification with Technological Artefacts.
            This is available on <a href="mailto://contact@drwip.com">request</a>. It is currently the best place to find references for the varied influences that helped shape this exhibition.
          </div>
        </div>
        <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
          <MessageOutlined style={{fontSize: "40px", padding: "20px"}}/>
          <div>
            This interactive exhibition is intended as a contribution to an ongoing conversation about the nature of reality, which I invite you to participate in.
            I hope to experiment with ways in which you can do that as part of my continued research into memory and meaning.
          </div>
        </div>
        </div>
      </div>

    </div>
  )
};

export default About
