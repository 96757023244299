import React from 'react';
import InfBannerText from "../components/InfBannerText";
import {Row, Typography} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEthereum, faFigma } from '@fortawesome/free-brands-svg-icons';

import {DatabaseOutlined, BuildOutlined} from "@ant-design/icons";
const {Title, Paragraph, Text} = Typography;

const Technology = () => {

  return (
    <div>
      <div style={{display: "flex", flexDirection: "column", "textAlign": "left", "padding": "0px auto", alignItems: "center"}}>
        <div style={{maxWidth: "900px", padding: "20px"}}>
          <Title style={{fontSize: "2em", fontFamily: "Montserrat, sans-serif"}}>
            How this exhibition was created
          </Title>
          <div style={{paddingTop: "10px"}}>

          <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
            {/*<EditOutlined style={{fontSize: "40px", padding: "20px"}}/>*/}
            <FontAwesomeIcon icon={faFigma} style={{fontSize: "40px", padding: "20px"}} />
            <div>
              Each diagram in this exhibition was either produced or translated from a sketch using <a target="_blank" href="https://www.figma.com/">Figma</a>.
            </div>
          </div>
          <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
            <BuildOutlined style={{fontSize: "40px", padding: "20px"}}/>
            <div>
              This exhibition itself was developed using <a target="_blank" href="https://docs.scaffoldeth.io/scaffold-eth/">scaffold-eth</a>. A wicked tool for fast prototyping ethereum applications.
            </div>
          </div>
          <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
            <FontAwesomeIcon icon={faEthereum} style={{fontSize: "40px", padding: "20px"}} />
            <div>
              Each of the twelve diagrams in this exhibition is an inscribed digital artifact, currently represented as an <a target="_blank" href="https://ethereum.org/en/developers/docs/standards/tokens/erc-1155/">ERC-1155 token</a>, minted to the <a target="_blank" href="https://sepolia.dev">sepolia test network</a>.
            </div>
          </div>
          <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
            <DatabaseOutlined style={{fontSize: "40px", padding: "20px"}} />
            <div>
              All the metadata associated with these tokens is pinned to my personal IPFS node and is currently served through a pinata gateway.
            </div>
          </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Technology
