import React from 'react'
import {convertToGatewayUrl, getFromIPFS, getFromPinataGateway} from "../helpers/ipfs";
import {Col, Image, Skeleton, Button, Typography} from 'antd';
import { Card } from 'antd';
import {useNavigate} from 'react-router-dom'
import {fetchExhibitData} from "../helpers/fetchExhibitData";
const { Title, Paragraph, Text } = Typography;

const ExhibitCard = ({exhibitId, readContracts, address}) => {
  let navigate = useNavigate();
  let [exhibit, setExhibit] = React.useState(null);

  React.useEffect(() => {
    const fetchExhibit = async () => {
      let exhibitData = null;


      try {
        let tokenSupply = await readContracts.IIExhibition.tokenSupply(exhibitId);
        let isMember = false;
        if (address) {
          isMember = await readContracts.IIExhibition.isMember(exhibitId, address);
        }

        let uri = await readContracts.IIExhibition.uri(exhibitId); //All tokens have the same base uri
        exhibitData = await fetchExhibitData(uri, exhibitId)

        // TODO: better error handling
        if (exhibitData) {
          exhibitData.supply =tokenSupply;
          exhibitData.isMember =isMember;
        }

      } catch (e) {
        console.log(e);
      }

      setExhibit(exhibitData);
    };
    readContracts && readContracts.IIExhibition && fetchExhibit();
  }, [readContracts]);

  return (
    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6}>
      {exhibit ?
        <Card
          className="exhibit-card"
          title={<Title style={{fontSize: "20px", fontFamily: "Montserrat, sans-serif"}}>{exhibitId}. {exhibit.name}</Title>}
          bordered={true}
          onClick={() => navigate(`/exhibit/${exhibitId}`)}
          // style={{width: "100%", border: "3px solid #222222",  backgroundColor: "#FFF"}}
          actions={[
            <Button type="link" onClick={() => navigate(`/exhibit/${exhibitId}`)}>View Exhibit</Button>
          ]}
        >

          <div className="picture-frame">
            <Image
              rootClassName="exhibit-card-image"
              width="100%"
              src={exhibit.image}
              preview={false}
            />
          </div>

        </Card>
        :
        <Card bordered={true}
        >
          <Skeleton.Image active/>
        </Card>
      }
    </Col>
  )

}

export default ExhibitCard
