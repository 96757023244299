import React, { useState } from "react";
import {Row, Typography} from 'antd';
import ExhibitCard from "../components/ExhibitCard";
import DrWipBanner from "../components/DrWipBanner";
const {Title, Paragraph, Text} = Typography;

export default function Exhibition({
  purpose,
  address,
  mainnetProvider,
  localProvider,
  yourLocalBalance,
  price,
  tx,
  readContracts,
  writeContracts,
}) {

  const exhibitIds = [1,2,3,4,5,6,7,8,9,10,11,12];

  return (
    <div>
      <div style={{padding: "0px 20px"}}>
        <Title style={{fontSize: "2em", fontFamily: "Montserrat, sans-serif"}}>
          Identity and Interaction <span>in</span> Complex Human Systems
        </Title>

      </div>


    <div style={{margin: "30px"}}>


      <Row justify="center" tyle={{fontSize: "1.5rem"}}gutter={[30,30]}>
        {exhibitIds.map(exhibitId => {
          return <ExhibitCard key={`exhibition:${exhibitId}`} exhibitId={exhibitId} address={address} readContracts={readContracts}/>
        })}
      </Row>

      {/*
        ⚙️ Here is an example UI that displays and sets the purpose in your smart contract:
      */}
    </div>
    </div>
  );
}
