import { useEffect } from "react";
import { fetchExhibitData } from "../helpers/fetchExhibitData";

export default function useLoadExhibitImages(readContracts, exhibitionAddress) {

  const exhibitIds = [1,2,3,4,5,6,7,8,9,10,11,12];


  useEffect(() => {
    const fetchExhibitImages = async () => {
      let imgLoadingPromises = exhibitIds.map(async (exhibitId) => {
        let exhibitData = null;
        try {
          let promises = [];
          let uri = await readContracts.IIExhibition.uri(exhibitId); //All tokens have the same base uri
          exhibitData = await fetchExhibitData(uri, exhibitId);
          const exhibitImage = new Image();
          exhibitImage.src = exhibitData.image;
          if (exhibitData.original) {
            const exhibitOriginal = new Image();
            exhibitOriginal.src = exhibitData.original;
            let originalLoadPromise = new Promise((resolve, reject) => {
              if (!exhibitOriginal.src) return resolve(null);
              exhibitOriginal.onload = () => {
                console.log("Exhibit Original Loaded", exhibitOriginal);
                return resolve(exhibitOriginal);
              }
              exhibitOriginal.onerror = () => reject(exhibitOriginal);
            });
            promises.push(originalLoadPromise);
          }



          let exhibitLoadPromise = new Promise((resolve, reject) => {
            exhibitImage.onload = () => {
              console.log("Exhibit Image Loaded", exhibitImage);
              return resolve(exhibitImage);
            }
            exhibitImage.onerror = () => reject(exhibitImage);
          });
          promises.push(exhibitLoadPromise);

          return Promise.all(promises);

        } catch (e) {
          console.log(e);
        }
      })


    };
    readContracts && readContracts.IIExhibition && fetchExhibitImages();
  }, [readContracts])

  return [];
}
