import React from 'react';
import {Typography} from 'antd';
import {StarOutlined, CommentOutlined, EyeOutlined, ShopOutlined} from "@ant-design/icons";
const {Title, Paragraph, Text} = Typography;
const Vision = () => {

  return (
    <div>
      <div style={{display: "flex", flexDirection: "column", "textAlign": "left", "padding": "0px auto", alignItems: "center"}}>
        <div style={{maxWidth: "900px", padding: "20px"}}>
          <Title style={{fontSize: "2em", fontFamily: "Montserrat, sans-serif"}}>
            Ambition for this exhibition in the future
          </Title>

          <div style={{paddingTop: "10px"}}>
            <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
              <StarOutlined style={{fontSize: "40px", padding: "20px"}}/>
              <div>
                Use exhibition as an experimental playground to explore memory and meaning in our digitally augmented society.
              </div>
            </div>
            <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
              <CommentOutlined style={{fontSize: "40px", padding: "20px"}}/>
              <div>
                Gather a community of interested, engaged and collaborative individuals to manage, curate and evolve the memory of this exhibition.
              </div>
            </div>
            <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
              <EyeOutlined style={{fontSize: "40px", padding: "20px"}}/>
              <div>
                Explore digital mechanisms to inscribe, authorize, curate, discover and attribute memories within this interactive exhibition.
              </div>
            </div>
            <div style={{display: "flex", fontSize: "20px", fontFamily: "Merriweather", alignItems: "center", background: "white", margin: "20px 0", padding: "10px", boxShadow: "0 0 2px 0 rgba(0,0,0,.25) inset, 0 2px 4px 2px rgba(0,0,0,.25)"}}>
              <ShopOutlined style={{fontSize: "40px", padding: "20px"}}/>
              <div>
                Instigate activity in the present in the form of discourse and alternative or derivative renderings with the ambition of hosting an exhibition IRL.
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
  )
};

export default Vision
