import React from 'react';
import Text from "antd/es/typography/Text";

const DrWipBanner = () => {

  return (
    <div style={{textAlign: "left", padding: "20px 0 0 20px", fontSize: "1.2em", fontFamily: "Merriweather"}}>
      A <a href={"https://drwip.com"} target="_blank" rel="noopener noreferrer">Dr. Wip</a> Exhibition
    </div>
  )
}

export default DrWipBanner
