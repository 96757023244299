import {useContractReader} from "eth-hooks";
import {ethers} from "ethers";
import React from "react";
import {Link} from "react-router-dom";
import {Button, Image, Typography, Layout, Space, Card, Tooltip} from 'antd'
import DrWipBanner from "../components/DrWipBanner";

const {Title, Paragraph, Text} = Typography;

/**
 * web3 props can be passed from '../App.jsx' into your local view component for use
 * @param {*} yourLocalBalance balance on current network
 * @param {*} readContracts contracts from current chain already pre-loaded using ethers contract module. More here https://docs.ethers.io/v5/api/contract/contract/
 * @returns react component
 **/
function Home({yourLocalBalance, readContracts}) {
  // you can also use hooks locally in your component of choice
  // in this case, let's keep track of 'purpose' variable from our contract
  // const purpose = useContractReader(readContracts, "IIExhibition", "purpose");


  return (
    <div style={{ width: "100%"}}>

      <div style={{display: "flex", flexDirection: "column", "justify-content": "center", padding: "20px", fontFamily: "Montserrat, sans-serif"}}>

        <Title className="exhibit-title">
          Identity and Interaction<br/> <span style={{fontSize: "2.5rem"}}>in</span> <br/>Complex Human Systems
        </Title>

        <div style={{fontSize: "2.5em", margin: "20px 0"}}>
          Abstract conceptual frames through which to perceive aspects of reality
        </div>
        <div style={{flexGrow: "1", paddingRight: "20px", display: "flex", "justify-content": "center"}}>
                      <span style={{float: "middle", fontSize: "3em"}}>
            <Link to={"/gallery"}>Enter</Link>
          </span>
        </div>
      </div>
      <div style={{fontFamily: "Montserrat", display: "flex", flexDirection: "column", alignItems: "center", fontSize: "2em"}}>
        <div style={{maxWidth: "1200px"}}>
          <div style={{margin: "10px 20px"}}>
            A playful exploration of our social reality produced during moments of contemplation and clarity
            throughout the course of my PhD journey.
          </div>
          {/*<div>Renderings</div>*/}
          <div style={{margin: "10px 20px"}}>
            This exhibition is presented as a celebration of that journey.
          </div>
          <div style={{margin: "10px 20px"}}>
            It is an ongoing experiment, not a polished product.
          </div>
        </div>


        {/*<div>*/}
          {/*The 12 models in this exhibition have been inscribed as digital artifacts, ERC-1155 tokens, currently deployed on the Goerli TestNetwork.*/}
        {/*</div>*/}
        {/*<div>*/}
          {/*I make no claims about these diagrams being correct, or complete, I simply believe they provide an interesting lens through which to think about identity and interaction.*/}
        {/*</div>*/}
        {/*<div style={{margin: "10px 20px"}}>*/}
          {/*These diagrams, models and musings are original and my own. Many originating in my notebook during one of my*/}
          {/*many wanders around the beautiful city of Edinburgh and its surroundings. However, the perspective and*/}
          {/*encoding of the knowledge and information within them come*/}
          {/*from many sources, writings and ideas from across our recent scientific history. In time I hope to reference*/}
          {/*and write about the key influences of this work more explicitly. For now, those influences are illustrated*/}
          {/*most clearly in my thesis.*/}
        {/*</div>*/}
      </div>


    </div>

  );
}

export default Home;
