import React from "react";
import { Typography } from "antd";
import {Link} from "react-router-dom";
import DrWipBanner from "./DrWipBanner";

const { Title, Text } = Typography;

// displays a page header

export default function Header({ link, title, subTitle, ...props }) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", padding: "1.2rem" }}>




      {props.children}
    </div>
  );
}

Header.defaultProps = {
  link: "https://drwip.com",
  title: "🏗Identity and Interaction in Complex Human Systems",
  subTitle: "A DrWip Exhibition",
};
