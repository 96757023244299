import React from 'react';
import useMedia from 'use-media';
import {Button, Col, Menu, Row} from "antd";
import {Link, useLocation} from "react-router-dom";
import DrWipBanner from "./DrWipBanner";
import App from "../App";

const AppNavBar = () => {
  const [collapsed, setCollapsed] = React.useState(false);
  const isMobile = useMedia({ maxWidth: 768 });
  const location = useLocation();

  React.useEffect(() => {
    setCollapsed(isMobile);
  }, [isMobile]);

  return (
    <div style={{marginBottom: 20, fontSize: "1.2em", fontFamily: "Merriweather", display: "flex", justifyContent: "center", alignItems: "center"}}>
      {/*<DrWipBanner/>*/}
      <div>
        <Menu style={{backgroundColor: "transparent", borderBottom: "none"}} selectedKeys={[location.pathname]} mode="horizontal">
          {/*<Menu.SubMenu style={{textAlign: "left"}} >*/}
          <Menu.Item key="/">
            <Link to="/">Entrance</Link>
          </Menu.Item>
          <Menu.Item key="/gallery">
            <Link to="/gallery">Gallery</Link>
          </Menu.Item>
          <Menu.Item key="/about">
            <Link to="/about">About</Link>
          </Menu.Item>

          <Menu.Item key="/technology">
            <Link to="/technology">Technology</Link>
          </Menu.Item>
          <Menu.Item key="/vision">
            <Link to="/vision">Vision</Link>
          </Menu.Item>
          {/*<Menu.Item key="/participate">*/}
            {/*<Link to="/participate">Participate</Link>*/}
          {/*</Menu.Item>*/}
        </Menu>

      </div>
    </div>
  )

};


export default AppNavBar
