import {convertToGatewayUrl, getFromIPFS, getFromPinataGateway} from "./ipfs";


export const fetchExhibitData = async function (uri, exhibitId) {
  // console.log("FETCH DATA", uri, exhibitId)
  uri = uri.replace(/{(.*?)}/, exhibitId);
  const ipfsHash = uri.replace("https://ipfs.io/ipfs/", "");
  const jsonManifest = await getFromPinataGateway(ipfsHash);
  let exhibit = null
  try {
    let imageUrl = convertToGatewayUrl(jsonManifest.image)
    let originalUrl = convertToGatewayUrl(jsonManifest.properties.original)
    exhibit = { id: exhibitId, name: jsonManifest.name, description: jsonManifest.description, image:imageUrl, original: originalUrl };
  } catch (e) {
    console.log(e);
  }
  return exhibit
}
